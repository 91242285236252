<template>
  <g fill="none" fill-rule="evenodd">
    <g fill="#FFF" fill-rule="nonzero">
      <g>
        <g>
          <path
            d="M41.994 14.74c.928 0 1.78.422 2.396 1.14l.09.122 1.901 3.003.057.061c.201.197.436.321.651.353l.105.008h4.322c.897 0 1.759.357 2.394.992.634.635.991 1.496.991 2.393v14.063c0 .898-.357 1.76-.991 2.394-.635.635-1.497.991-2.394.991H25.734c-.898 0-1.759-.356-2.393-.991-.635-.635-.992-1.496-.992-2.394V22.812c0-.897.357-1.758.992-2.393.634-.635 1.496-.992 2.393-.992h.684v-.716c0-.396.294-.722.675-.774l.106-.007h1.758c.396 0 .722.294.774.675l.007.106v.716h.39c.216 0 .444-.113.68-.354l.058-.064 1.904-3.007.09-.121c.616-.719 1.468-1.141 2.396-1.141zm0 2.083h-6.738c-.296 0-.58.141-.815.414l.038-.04-1.898 3-.09.12c-.635.743-1.438 1.193-2.362 1.193h-4.395c-.345 0-.676.138-.92.382-.245.244-.382.575-.382.92v14.063c0 .345.138.677.382.92.244.245.575.382.92.382h25.782c.345 0 .676-.137.92-.381.245-.244.382-.576.382-.921V22.812c0-.345-.137-.676-.382-.92-.244-.244-.575-.382-.92-.382h-4.322c-.927 0-1.812-.464-2.436-1.193l-.089-.12-1.9-3.002-.063-.067c-.177-.168-.377-.268-.586-.296l-.126-.01zm-3.369 4.948c3.811 0 6.901 3.09 6.901 6.9 0 3.812-3.09 6.902-6.901 6.902s-6.901-3.09-6.901-6.901c0-3.812 3.09-6.901 6.901-6.901zm0 2.083c-2.66 0-4.818 2.157-4.818 4.818 0 2.66 2.157 4.818 4.818 4.818 2.66 0 4.818-2.157 4.818-4.818 0-2.66-2.157-4.818-4.818-4.818z"
            transform="translate(-222 -540) translate(0 114) translate(200 412)"
          />
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
